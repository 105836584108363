import React, { useEffect, useState, useRef } from 'react';
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';
import useTimeout from 'hooks/useTimeout';

// import ReactPlayer from 'react-player';

// import reportWebVitals from './reportWebVitals';

// import { getPerformance } from "firebase/performance";

// import {
//   getAuth,
//   // isSignInWithEmailLink,
//   sendSignInLinkToEmail,
//   // signInWithEmailLink,
// } from 'firebase/auth';

import { FaTwitter } from 'react-icons/fa';
// import { RiLoginBoxLine } from 'react-icons/ri';

// import { isMobile } from 'react-device-detect';

import { getClassesString } from 'utils';
import { ReactComponent as GrabGamesLogo } from 'assets/icons/GrabGamesLogo.svg';
// import { ReactComponent as GrabLabsLogo } from 'assets/icons/GrabLabsLogo.svg';

import teaser from 'assets/video/Year10_Teaser_v1_Horz_2048x1080.mp4';
import teaserVertical from 'assets/video/Year10_Teaser_v1_Vert_1170x2532.mp4';

import posterImage from 'assets/img/Year10_Poster_Teaser_v1_Horz_2048x1080.jpg';
import verticalPosterImage from 'assets/img/Year10_Poster_Teaser_v1_Vert_1170x2532.jpg';

// import MetaTags from 'components/metaTags';
// import teaser from 'assets/video/test30.mp4';
// import teaserVertical from 'assets/video/test30_mobile.mp4';

import styles from './index.module.css';

const firebaseConfig = {
  apiKey: 'AIzaSyBK1ebg3o8M3zxKIXLAuqLxDufPdguUSB4',
  authDomain: 'year-10-site.firebaseapp.com',
  projectId: 'year-10-site',
  storageBucket: 'year-10-site.appspot.com',
  messagingSenderId: '583816820188',
  appId: '1:583816820188:web:8bfc2923adc18993940b19',
  measurementId: 'G-242WLCTXZM',
};

const firebaseApp = initializeApp(firebaseConfig);
const firebaseAnalytics = getAnalytics(firebaseApp);
// const firebasePerf = getPerformance(firebaseApp);

// function sendToGoogleAnalytics({ name, delta, id }) {
//   // Assumes the global `gtag()` function exists, see:
//   // https://developers.google.com/analytics/devguides/collection/gtagjs
//   logEvent(firebaseAnalytics, name, {
//     event_category: 'Web Vitals',
//     // The `id` value will be unique to the current page load. When sending
//     // multiple values from the same page (e.g. for CLS), Google Analytics can
//     // compute a total by grouping on this ID (note: requires `eventLabel` to
//     // be a dimension in your report).
//     event_label: id,
//     // Google Analytics metrics must be integers, so the value is rounded.
//     // For CLS the value is first multiplied by 1000 for greater precision
//     // (note: increase the multiplier for greater precision if needed).
//     value: Math.round(name === 'CLS' ? delta * 1000 : delta),
//     // Use a non-interaction event to avoid affecting bounce rate.
//     non_interaction: true,

//     // OPTIONAL: any additional params or debug info here.
//     // See: https://web.dev/debug-web-vitals-in-the-field/
//     // metric_rating: 'good' | 'ni' | 'poor',
//     // debug_info: '...',
//     // ...
//   });
// }

// reportWebVitals(sendToGoogleAnalytics);
// const auth = getAuth();

const twitterUrl = `https://twitter.com/year10game`;
// const grabGamesUrl = `https://www.grabgames.com/`;
// const grabLabsUrl = `https://grablabs.com/`;

const Lander = (props) => {
  const [showFooter, setShowFooter] = useState(false);

  // show footer on scroll
  useEffect(() => {
    const onScroll = (e) => {
      setShowFooter(true);
    };

    window.addEventListener('mousewheel', onScroll);
    return () => {
      window.removeEventListener('mousewheel', onScroll);
    };
  });

  const [isPortrait, setIsPortrait] = useState(
    window.innerHeight > window.innerWidth
  );

  // set isPortrait on resize or orientation change
  useEffect(() => {
    const onResize = (e) => {
      const newIsPortrait = window.innerHeight > window.innerWidth;
      setIsPortrait(newIsPortrait);
    };

    window.addEventListener('resize', onResize);
    window.addEventListener('orientationchange', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
      window.removeEventListener('orientationchange', onResize);
    };
  });

  // show footer automatically after 5 seconds
  useTimeout(() => setShowFooter(true), 7 * 1000);

  // const [err, setErr] = useState(null);
  // const [enteredEmail, setEnteredEmail] = useState('');
  // const [prompt, setPrompt] = useState('');

  // const queryParams = new URLSearchParams(window.location.search);
  // const signedInEmail = null; // window.localStorage.getItem('signedInEmail' ?? null);
  // const emailForSignIn =
  //   (window.localStorage.getItem('emailForSignIn') ||
  //     queryParams.get('email')) ??
  //   null;

  //Email signing
  // const actionCodeSettings = {
  //   // URL you want to redirect back to. The domain (www.example.com) for this
  //   // URL must be in the authorized domains list in the Firebase Console.
  //   url: 'http://localhost:3000/?email=' + enteredEmail,
  //   // This must be true.
  //   handleCodeInApp: true,
  //   // iOS: {
  //   //   bundleId: 'com.example.ios'
  //   // },
  //   // android: {
  //   //   packageName: 'com.example.android',
  //   //   installApp: true,
  //   //   minimumVersion: '12'
  //   // },
  //   // dynamicLinkDomain: 'example.page.link'
  // };

  // const sendEmailConfirmation = (email) => {
  //   window.localStorage.removeItem('signedInEmail');
  //   return sendSignInLinkToEmail(auth, email, actionCodeSettings)
  //     .then(() => {
  //       console.log('Successful sendSignInLinkToEmail:' + email);

  //       logEvent(firebaseAnalytics, 'email_signup_intent');
  //       // The link was successfully sent. Inform the user.
  //       // Save the email locally so you don't need to ask the user for it again
  //       // if they open the link on the same device.
  //       window.localStorage.setItem('emailForSignIn', email);
  //       setPrompt(`Check ${email} for confirmation`);
  //       // ...
  //     })
  //     .catch((error) => {
  //       // Some error occurred, you can inspect the code: error.code, error.message
  //       // Common errors could be invalid email and invalid or expired OTPs.
  //       console.log({ error });
  //       setErr(error.message);
  //       // ...
  //     });
  // };
  // Temp - on page load
  useEffect(() => {
    return;
    // setErr(null);
    // if (isSignInWithEmailLink(auth, window.location.href)) {
    //   // Additional state parameters can also be passed via URL.
    //   // This can be used to continue the user's intended action before triggering
    //   // the sign-in operation.
    //   // Get the email if available. This should be available if the user completes
    //   // the flow on the same device where they started it.
    //   let email = window.localStorage.getItem('emailForSignIn');
    //   if (!email) {
    //     console.log('No localStorage:emailForSignIn found');
    //     // User opened the link on a different device. To prevent session fixation
    //     // attacks, ask the user to provide the associated email again. For example:
    //     email = window.prompt('Please provide your email for confirmation');
    //   } else {
    //     console.log('Found localStorage:emailForSignIn:' + email);
    //   }
    //   // The client SDK will parse the code from the link for you.
    //   signInWithEmailLink(auth, email, window.location.href)
    //     .then((result) => {
    //       console.log({ result });
    //       // Clear email from storage.
    //       window.localStorage.removeItem('emailForSignIn');
    //       window.localStorage.setItem('signedInEmail', email);
    //       // TODO: Perhaps don't clear this so we know they signed in already?
    //       // You can access the new user via result.user
    //       // Additional user info profile not available via:
    //       // result.additionalUserInfo.profile == null
    //       // You can check if the user is new or existing:
    //       // result.additionalUserInfo.isNewUser
    //     })
    //     .catch((error) => {
    //       setErr(error.message);
    //       // Some error occurred, you can inspect the code: error.code, error.message
    //       // Common errors could be invalid email and invalid or expired OTPs.
    //       console.log({ error });
    //       // should redirect to root, maybe after a button press?
    //       window.location.href = '/';
    //     });
    // }
  }, []);

  // const handleChange = (event) => {
  //   setEnteredEmail(event.target.value);
  // };

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   sendEmailConfirmation(enteredEmail);
  // };

  const [videoIsLoading, setVideoIsLoading] = useState(null);
  const [videoIsLoaded, setVideoIsLoaded] = useState(null);
  const [videoError, setVideoError] = useState(null);
  const [videoIsPlaying, setVideoIsPlaying] = useState(false);

  const handleVideoEvent = (evt, data) => {
    //onLoadStart
    //onProgress
    //onLoadedData
    //onCanPlay
    // console.log(evt);
    if (evt === 'onLoadStart') {
      setVideoIsLoading(true);
    } else if (evt === 'onLoadedData') {
      setVideoIsLoaded(true);
      setVideoIsLoading(false);
    } else if (evt === 'onPlay') {
      setVideoIsPlaying(true);
    } else if (evt === 'onPause') {
      setVideoIsPlaying(false);
    } else if (evt === 'onError') {
      setVideoIsLoading(false);
      setVideoIsLoaded(false);
      setVideoIsPlaying(false);
      setVideoError('Unknown Error');
      console.log(evt?.target);
    }
  };

  if (process.env.NODE_ENV === 'development') {
    console.log({
      videoIsLoading,
      videoIsLoaded,
      videoIsPlaying,
      videoError,
    });
  }
  const videoRef = useRef(null);
  useEffect(() => {
    // console.log(videoRef);
    if (videoRef?.current && videoIsLoaded) {
      videoRef.current?.play();
      // setTimeout(() => {
      //   videoRef.current.currentTime = 0;
      // }, 5000);
      //pause
      logEvent(firebaseAnalytics, 'videoLoaded', {
        event_category: 'Content',
        event_label: 'loadVideo',
        value: 1, //perhaps note how long it took to load (in seconds)
        // Perhaps we also note 'mobile' or 'desktop' loaded
        // Use a non-interaction event to avoid affecting bounce rate.
        non_interaction: true,
      });
    }
    // could listen to !videoIsPlaying, to prevent it from 'pausing' for random reasons
  }, [videoIsLoaded]);

  // const showSubscriptionForm = !signedInEmail && !emailForSignIn;

  const videoProps = {
    ref: videoRef,
    className: styles.video,
    preload: 'auto',
    muted: true,
    // autoPlay
    // width
    // height
    // poster
    loop: true,
    playsInline: true,
    disableRemotePlayback: true,
    onError: (data) => handleVideoEvent('onError', data),
    onLoadedData: (data) => handleVideoEvent('onLoadedData', data),
    onLoadStart: (data) => handleVideoEvent('onLoadStart', data),
    onPause: (data) => handleVideoEvent('onPause', data),
    onPlay: (data) => handleVideoEvent('onPlay', data),
  };

  const videoSrc = isPortrait ? teaserVertical : teaser;
  const posterSrc = isPortrait ? verticalPosterImage : posterImage;

  useEffect(() => {
    setVideoIsLoaded(undefined);
    videoRef.current.load();
  }, [videoSrc]);

  // const buttonClicked = (event, buttonName) => {
  //   // event.stopPropagation();
  //   logEvent(firebaseAnalytics, buttonName, {
  //     event_category: 'Social',
  //     event_label: 'buttonClick',
  //     value: 1,
  //     // Use a non-interaction event to avoid affecting bounce rate.
  //     non_interaction: false,
  //   });
  // };
  return (
    <React.StrictMode>
      <div
        className={getClassesString([
          styles.lander,
          // videoIsPlaying && styles.staticBackground,
          styles.staticBackground,
        ])}
      >
        <div
          className={getClassesString([
            styles.videoView,
            isPortrait && styles.verticalVideo,
            !videoIsLoading && styles.showVideo,
          ])}
        >
          <div className={styles.grabLogo}>
            <GrabGamesLogo className={styles.icon} />
          </div>
          <video
            {...videoProps}
            style={{ backgroundImage: `url(${posterSrc})` }}
          >
            <source src={videoSrc} type='video/mp4' />
          </video>
        </div>
        <div
          className={getClassesString([
            styles.footer,
            showFooter && styles.show,
          ])}
        >
          <a className={styles.socialButton} href={twitterUrl}>
            <FaTwitter className={styles.icon} />
            <span className={styles.label}>@Year10Game</span>
          </a>
          {/* <a className={styles.socialButton} href={twitterUrl}>
          <RiLoginBoxLine className={styles.icon} />
          <span className={styles.label}>Sign up</span>
        </a> */}
          {/* <a className={styles.socialButton} href={grabGamesUrl}>
          <GrabGamesLogo className={styles.icon} />
          <span className={styles.label}>Grab Games</span>
        </a>
        <a className={styles.socialButton} href={grabLabsUrl}>
          <GrabLabsLogo className={styles.icon} />
          <span className={styles.label}>Grab Labs</span>
        </a> */}
        </div>

        {/* {false && showSubscriptionForm && (
          <form onSubmit={handleSubmit}>
            Year 10: Track the signal <br />
            {prompt}
            {err}
            <label>
              Email:
              <input
                type='email'
                value={enteredEmail}
                onChange={handleChange}
              />
            </label>
            <input type='submit' value='Track' />
          </form>
        )} */}
      </div>
    </React.StrictMode>
  );
};

export default Lander;
