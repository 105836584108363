import { useEffect, useRef } from 'react';

/**
 * Wraps setTimeout, Note: that the useUpdatableTimeout is a more user friendly version of this
 * @param {function} callback triggered after delay with no args
 * @param {number|null} delay in milliseconds before callback is triggered. null prevents a trigger or stops existing
 */
export default function useTimeout(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  });

  // Set up the interval.
  useEffect(() => {
    function done() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setTimeout(done, delay);
      return () => clearTimeout(id);
    }
  }, [delay]);
}

// TODO: Example use cases
